import { Card, Code, Group, Image, Stack, Text, createStyles } from '@mantine/core'
import React from 'react'

import { CHARACTER_SENTIMENTS, getParsedCharacterMetadata } from '../../utils/character'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    cursor: 'pointer',
  },
}))

interface BadgeCardProps {
  id: string
  image: string
  gender: string
  title: string
  metaData: string
  onClick: (id: string) => void
}

export function HorizontalCharacterCard({
  id,
  image,
  title,
  metaData,
  gender,
  onClick,
}: BadgeCardProps) {
  const { classes, theme } = useStyles()
  const parsedMetaData = getParsedCharacterMetadata(metaData)

  const sentiment = CHARACTER_SENTIMENTS.find(({ value }) => value === parsedMetaData.sentiment)

  return (
    <Card radius="md" p="xs" className={classes.card} onClick={() => onClick(id)}>
      <Group spacing="xs">
        <Group>
          <Image src={image} alt={title} radius="lg" height={95} width={95} />
        </Group>
        <Stack spacing="0.25rem" sx={{ flex: 1 }}>
          <Text
            fz="lg"
            fw={600}
            lineClamp={1}
            variant="gradient"
            gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
          >
            {title}
          </Text>
          <Text fz="xs" mt="0" lineClamp={2} lh={1.3} h="30px">
            {parsedMetaData.characterDescription}
          </Text>
          <Group position="apart" spacing={7} mt="0.25rem">
            <Code color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}>{gender}</Code>
            <Code color={theme.colorScheme === 'dark' ? 'yellow.5' : 'yellow.1'}>
              {sentiment?.label}
            </Code>
          </Group>
        </Stack>
      </Group>
    </Card>
  )
}
