import { ActionIcon, Badge, Card, Code, Group, Image, Text, createStyles } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import React from 'react'

import { UserRoleEnum } from '../../../generated/graphql'
import { NewCharacter } from '../../routes/Characters/NewCharacter'
import { useAuth } from '../../utils/auth'
import { CHARACTER_SENTIMENTS, getParsedCharacterMetadata } from '../../utils/character'
// import { IconHeart } from '@tabler/icons-react'
import { StartConversation } from './StartConversation'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  section: {
    flex: 1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}))

interface BadgeCardProps {
  id: string
  image: string
  gender: string
  title: string
  metaData: string
}

export function Character({ id, image, title, metaData, gender }: BadgeCardProps) {
  const { classes, theme } = useStyles()
  const {
    data: { User },
  } = useAuth()
  const parsedMetaData = getParsedCharacterMetadata(metaData)

  const sentiment = CHARACTER_SENTIMENTS.find(({ value }) => value === parsedMetaData.sentiment)

  return (
    <Card withBorder radius="md" p="sm" className={classes.card}>
      <Card.Section>
        <Image src={image} alt={title} height={180} />
        {User.role === UserRoleEnum.SuperAdmin && (
          <NewCharacter characterId={id}>
            <ActionIcon pos="absolute" top="0.5rem" right="0.5rem" variant="subtle" color="blue">
              <IconEdit size="1rem" />
            </ActionIcon>
          </NewCharacter>
        )}
      </Card.Section>

      <Card.Section className={classes.section} mt="xs">
        <Group position="apart">
          <Text
            fz="lg"
            fw={600}
            lineClamp={1}
            variant="gradient"
            gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
          >
            {title}
          </Text>
          {parsedMetaData.isNsfw && (
            <Badge size="sm" color="red">
              NSFW
            </Badge>
          )}
        </Group>
        <Text fz="xs" mt="0" lineClamp={5} lh={1.3} h="77px">
          {parsedMetaData.characterDescription}
        </Text>
        <Group position="apart" spacing={7} mt="xs">
          <Code color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}>{gender}</Code>
          <Code color={theme.colorScheme === 'dark' ? 'yellow.5' : 'yellow.1'}>
            {sentiment?.label}
          </Code>
        </Group>
      </Card.Section>

      <Group w="100%">
        <StartConversation id={id} title={title} metaData={metaData} />
        {/*<ActionIcon variant="default" radius="md" size={36}>*/}
        {/*  <IconHeart size="1.1rem" className={classes.like} stroke={1.5} />*/}
        {/*</ActionIcon>*/}
      </Group>
    </Card>
  )
}
