import {
  Box,
  Button,
  Center,
  Drawer,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  Stack,
  Switch,
  TextInput,
  Textarea,
  Title,
  getDefaultZIndex,
  useMantineTheme,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { Character } from '@prisma/client'
import { IconCheck, IconGenderFemale, IconGenderMale, IconX } from '@tabler/icons-react'
import React from 'react'

import {
  CharacterInput,
  GenderEnum,
  SubscriptionPlan,
  UserRoleEnum,
  useCharacterQuery,
  useCreateCharacterMutation,
  useEditCharacterMutation,
} from '../../../generated/graphql'
import { CharacterSentimentSelect, StartConversation } from '../../components/Character'
import { useSubscriptionValidity } from '../../utils/SubscriptionValidity'
import { useAuth } from '../../utils/auth'
import {
  CharacterMetadata,
  DEFAULT_CHARACTER_METADATA,
  getParsedCharacterMetadata,
} from '../../utils/character'
import { RefetchQueriesEnum } from '../../utils/constants'
import { PublicImages } from './PublicImages'

interface Props {
  characterId?: string
  children: React.ReactElement<React.DOMAttributes<HTMLButtonElement>>
}

type CharacterMetadataFormValue = CharacterMetadata

interface CharacterFormValues {
  name: string
  imageS3FileKey: string | null
  gender: GenderEnum
  isPublic: boolean
  isVisible: boolean
}

export const CharacterSwitch: React.FC<{
  label: string
  value: boolean
  onChange: (newValue: boolean) => void
}> = ({ label, value, onChange }) => {
  const theme = useMantineTheme()

  return (
    <Flex justify="space-between">
      <Input.Label onClick={() => onChange(!value)}>{label}</Input.Label>
      <Switch
        onChange={() => onChange(!value)}
        checked={value}
        width="100%"
        color="teal"
        size="md"
        labelPosition="left"
        thumbIcon={
          value ? (
            <IconCheck
              size="0.8rem"
              color={theme.colors.teal[theme.fn.primaryShade()]}
              stroke={3}
            />
          ) : (
            <IconX size="0.8rem" color={theme.colors.red[theme.fn.primaryShade()]} stroke={3} />
          )
        }
      />
    </Flex>
  )
}

export const NewCharacter: React.FC<Props> = ({ characterId, children }) => {
  const theme = useMantineTheme()
  const {
    data: { User },
  } = useAuth()
  const subscriptionValidity = useSubscriptionValidity()
  const [createCharacterMutation, createCharacterMutationData] = useCreateCharacterMutation()
  const [editCharacterMutation, editCharacterMutationData] = useEditCharacterMutation()

  const [opened, { open, close }] = useDisclosure(false)
  const [openConversationForCharacter, setOpenConversationForCharacter] =
    React.useState<Partial<Character> | null>(null)
  const characterForm = useForm<CharacterFormValues>({
    initialValues: {
      name: '',
      gender: GenderEnum.Male,
      imageS3FileKey: null,
      isPublic: false,
      isVisible: true,
    },
  })
  const characterMetadataForm = useForm<CharacterMetadataFormValue>({
    initialValues: { ...DEFAULT_CHARACTER_METADATA },
  })

  const { data: characterData } = useCharacterQuery({
    variables: { id: characterId! },
    skip: !characterId,
  })

  React.useEffect(() => {
    if (characterData?.character) {
      const { gender, isPublic, metaData, title, ImageS3File } = characterData.character

      characterForm.setValues({
        isPublic,
        name: title,
        gender,
        imageS3FileKey: ImageS3File?.key,
      })

      characterMetadataForm.setValues(getParsedCharacterMetadata(metaData))

      characterForm.resetDirty()
      characterMetadataForm.resetDirty()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterData?.character?.id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { name, isPublic, isVisible, imageS3FileKey, gender } = characterForm.values
    const { isNsfw, sentiment, characterDescription, instructions } = characterMetadataForm.values

    const inputData: CharacterInput = {
      title: name,
      gender,
      isPublic,
      isVisible,
      userId: User.id!,
      imageS3FileKey,
      metaData: JSON.stringify({
        characterDescription,
        isNsfw,
        instructions,
        sentiment,
      }),
    }

    if (!characterId) {
      const { data } = await createCharacterMutation({
        variables: { inputData },
        awaitRefetchQueries: true,
        refetchQueries: [RefetchQueriesEnum.Characters],
      })
      if (data) {
        close()
        setOpenConversationForCharacter(data.createCharacter ?? null)
        characterForm.reset()
        characterMetadataForm.reset()
      }
    } else {
      const { data } = await editCharacterMutation({
        variables: { id: characterId, inputData },
        awaitRefetchQueries: true,
        refetchQueries: [RefetchQueriesEnum.Characters],
      })
      if (data) {
        close()
      }
    }
  }

  const handleOpen = () => {
    if (
      subscriptionValidity.validate({
        plansAllowed: [SubscriptionPlan.Premium, SubscriptionPlan.Pro],
      })
    ) {
      open()
    }
  }

  return (
    <>
      {React.cloneElement<React.DOMAttributes<HTMLButtonElement>>(children, {
        onClick: handleOpen,
      })}
      <Drawer
        opened={opened}
        size="md"
        position="right"
        onClose={close}
        title={<Title size="large">New Character</Title>}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <LoadingOverlay
          visible={createCharacterMutationData.loading || editCharacterMutationData.loading}
          overlayBlur={2}
          zIndex={getDefaultZIndex('max')}
        />
        <form id="character-settings" onSubmit={handleSubmit}>
          <Stack spacing="sm">
            <PublicImages
              selectedFileKey={characterForm.values.imageS3FileKey}
              onFileKeySelect={(key) => characterForm.setFieldValue('imageS3FileKey', key)}
            />
            <TextInput label="Name" placeholder="Name" {...characterForm.getInputProps('name')} />
            <Stack spacing={0}>
              <Input.Label>Gender</Input.Label>
              <SegmentedControl
                {...characterForm.getInputProps('gender')}
                data={[
                  {
                    label: (
                      <Center>
                        <IconGenderMale size="1rem" />
                        <Box ml={10}>Male</Box>
                      </Center>
                    ),
                    value: GenderEnum.Male,
                  },
                  {
                    label: (
                      <Center>
                        <IconGenderFemale size="1rem" />
                        <Box ml={10}>Female</Box>
                      </Center>
                    ),
                    value: GenderEnum.Female,
                  },
                ]}
              />
            </Stack>
            {User.role === UserRoleEnum.SuperAdmin && (
              <>
                <CharacterSwitch
                  label="Public"
                  value={characterForm.values.isPublic}
                  onChange={(isPublic) => characterForm.setValues({ isPublic })}
                />
                <CharacterSwitch
                  label="Visible"
                  value={characterForm.values.isVisible}
                  onChange={(isVisible) => characterForm.setValues({ isVisible })}
                />
                <CharacterSwitch
                  label="NSFW"
                  value={characterMetadataForm.values.isNsfw}
                  onChange={(isNsfw) => characterMetadataForm.setValues({ isNsfw })}
                />
              </>
            )}
            <Textarea
              {...characterMetadataForm.getInputProps('characterDescription')}
              label="Character description"
              placeholder="Add character description."
              autosize
              minRows={2}
              maxRows={6}
            />
            <CharacterSentimentSelect
              sentiment={characterMetadataForm.values.sentiment}
              onSentimentChange={(sentiment) => characterMetadataForm.setValues({ sentiment })}
            />
            <Textarea
              {...characterMetadataForm.getInputProps('instructions')}
              label="Instructions (optional)"
              placeholder="Add specific instructions to the character."
              autosize
              minRows={2}
              maxRows={6}
            />
            <Button type="submit">Save</Button>
          </Stack>
        </form>
      </Drawer>
      <Modal
        opened={!!openConversationForCharacter}
        onClose={() => setOpenConversationForCharacter(null)}
        title="Start conversation"
      >
        {openConversationForCharacter && (
          <StartConversation
            id={openConversationForCharacter.id!}
            title={openConversationForCharacter.title!}
            metaData={openConversationForCharacter.metaData!}
          />
        )}
      </Modal>
    </>
  )
}
