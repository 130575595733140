import { Group, LoadingOverlay, Modal, Text, getDefaultZIndex } from '@mantine/core'
import { IconFileText, IconForms, IconPdf, IconTable } from '@tabler/icons-react'
import { useRouter } from 'next/router'
import React from 'react'

import { DocumentTypeEnum, useCreateDocumentConversationMutation } from '../../../generated/graphql'
import { RoutesMap } from '../../constants'
import { useAuth } from '../../utils/auth'
import { CreateSourceDocument } from '../CreateSourceDocument'

export const documentTypes = [
  { title: 'Url link', icon: IconForms, value: DocumentTypeEnum.UrlArticle },
  { title: 'PDF', icon: IconPdf, value: DocumentTypeEnum.Pdf },
  { title: 'Word', icon: IconFileText, value: DocumentTypeEnum.Word },
  { title: 'Excel', icon: IconTable, value: DocumentTypeEnum.Excel },
]

interface Props {
  characterId: string
  title: string
  metaData: string
  isOpen: boolean
  onClose: () => void
}

export const NewDocumentConversationModal = ({
  characterId,
  title,
  metaData,
  isOpen,
  onClose,
}: Props) => {
  const router = useRouter()
  const {
    data: { User },
  } = useAuth()
  const [createDocumentConversationMutation, createDocumentConversationMutationData] =
    useCreateDocumentConversationMutation()
  const parsedMetaData = React.useMemo<Record<string, string>>(() => {
    let parsed = {}
    try {
      parsed = JSON.parse(metaData) || {}
    } catch {}
    return parsed
  }, [metaData])

  const handleStartConversation = async (sourceDocumentId: string) => {
    const { data } = await createDocumentConversationMutation({
      variables: {
        inputData: {
          userId: User.id!,
          characterId: characterId,
          title: `${title} conversation`,
          sourceDocumentId,
          documentType: parsedMetaData.documentType as DocumentTypeEnum,
        },
      },
    })
    const conversationId = data?.createDocumentConversation?.id ?? null
    if (conversationId) {
      await router.push(`${RoutesMap.conversation}/${conversationId}`)
    }
  }

  const { icon: DocumentTypeIcon, title: documentTypeTitle } =
    documentTypes.find(({ value }) => value === parsedMetaData.documentType) ?? {}
  const documentType = parsedMetaData.documentType as DocumentTypeEnum

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={
        <Group align="center">
          {DocumentTypeIcon && <DocumentTypeIcon />} <Text size="xl">{documentTypeTitle}</Text>
        </Group>
      }
    >
      <CreateSourceDocument
        documentType={documentType}
        hasUploader={documentType !== DocumentTypeEnum.UrlArticle}
        onStartConversation={(sourceDocumentId) => {
          if (sourceDocumentId) {
            void handleStartConversation(sourceDocumentId)
            onClose()
          }
        }}
      />
      <LoadingOverlay
        visible={createDocumentConversationMutationData.loading}
        overlayBlur={2}
        zIndex={getDefaultZIndex('max')}
      />
    </Modal>
  )
}
