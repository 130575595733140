import { Button } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useRouter } from 'next/router'
import React from 'react'

import { SubscriptionPlan, useCreateConversationMutation } from '../../../generated/graphql'
import { RoutesMap } from '../../constants'
import { useSubscriptionValidity } from '../../utils/SubscriptionValidity'
import { useAuth } from '../../utils/auth'
import { NewDocumentConversationModal } from './NewDocumentConversationModal'

interface Props {
  id: string
  title: string
  metaData: string
}

export const StartConversation = ({ id, title, metaData }: Props) => {
  const router = useRouter()
  const subscriptionValidity = useSubscriptionValidity()
  const {
    data: { User },
  } = useAuth()
  const [createConversationMutation, createConversationMutationData] =
    useCreateConversationMutation()
  const parsedMetaData = React.useMemo<Record<string, string>>(() => {
    let parsed = {}
    try {
      parsed = JSON.parse(metaData) || {}
    } catch {}
    return parsed
  }, [metaData])
  const [opened, { open, close }] = useDisclosure(false)

  const handleStartConversation = async () => {
    if (
      subscriptionValidity.validate({
        creditsNeeded: true,
        plansAllowed: [SubscriptionPlan.Basic, SubscriptionPlan.Premium, SubscriptionPlan.Pro],
      })
    ) {
      const { data } = await createConversationMutation({
        variables: {
          inputData: { userId: User.id!, characterId: id, title: `${title} conversation` },
        },
      })
      const conversationId = data?.createConversation?.id ?? null
      if (conversationId) {
        await router.push(`${RoutesMap.conversation}/${conversationId}`)
      }
    }
  }

  return (
    <>
      <NewDocumentConversationModal
        title={title}
        characterId={id}
        metaData={metaData}
        isOpen={opened}
        onClose={close}
      />

      <Button
        variant="gradient"
        gradient={{ from: 'teal', to: 'lime', deg: 105 }}
        radius="md"
        style={{ flex: 1 }}
        onClick={() => (parsedMetaData.documentType ? open() : handleStartConversation())}
        loading={createConversationMutationData.loading}
      >
        Start chat
      </Button>
    </>
  )
}
