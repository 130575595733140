import { ActionIcon, Stack, Text, TextInput, useMantineTheme } from '@mantine/core'
import { MIME_TYPES } from '@mantine/dropzone'
import { IconArrowRight, IconForms } from '@tabler/icons-react'
import React from 'react'

import { useCreateSourceDocumentMutation } from '../../../generated/graphql'
import { DocumentTypeEnum } from '../../../generated/graphql'
import { SourceDocumentDragAndDropUploader } from '../../components/SimpleDragAndDropUploader'

const MIME_TYPES_MAP = {
  [DocumentTypeEnum.Pdf]: [MIME_TYPES.pdf],
}

interface Props {
  documentType: DocumentTypeEnum
  hasUploader: boolean
  onStartConversation: (id: string) => void
}

export const CreateSourceDocument = ({ documentType, hasUploader, onStartConversation }: Props) => {
  const theme = useMantineTheme()

  const [createSourceDocumentMutation, createSourceDocumentMutationData] =
    useCreateSourceDocumentMutation()
  const [fileUrl, setFileUrl] = React.useState('')

  return (
    <Stack align="stretch">
      <TextInput
        value={fileUrl}
        onChange={(event) => setFileUrl(event.currentTarget.value)}
        icon={<IconForms size="1.1rem" stroke={1.5} />}
        radius="md"
        size="md"
        rightSection={
          <ActionIcon
            size={32}
            radius="md"
            color={theme.primaryColor}
            variant="filled"
            loading={createSourceDocumentMutationData.loading}
            onClick={async () => {
              const { data } = await createSourceDocumentMutation({
                variables: {
                  inputData: {
                    fileUrl,
                    documentType,
                  },
                },
              })
              if (data?.createSourceDocument?.id) {
                onStartConversation(data.createSourceDocument.id)
              }
            }}
          >
            <IconArrowRight size="1.1rem" stroke={1.5} />
          </ActionIcon>
        }
        placeholder="Paste link"
        rightSectionWidth={42}
      />
      {hasUploader && (
        <>
          <Text align="center" size="xl" weight="bold">
            OR
          </Text>
          <SourceDocumentDragAndDropUploader
            isDisabled={createSourceDocumentMutationData.loading}
            accept={MIME_TYPES_MAP[documentType]}
            onUploadStart={() => {}}
            onFileUploaded={() => {}}
            documentType={documentType}
            onDocumentUploaded={(_, sourceDocumentId) => {
              if (sourceDocumentId) {
                onStartConversation(sourceDocumentId)
              }
            }}
          />
        </>
      )}
    </Stack>
  )
}
