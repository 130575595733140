import { Avatar, Box, Group, Select, SelectProps, Text } from '@mantine/core'
import React from 'react'

import { CHARACTER_SENTIMENTS, CharacterSentimentEnum } from '../../utils/character'

interface Props extends Pick<SelectProps, 'variant'> {
  sentiment: CharacterSentimentEnum
  onSentimentChange: (sentiment: CharacterSentimentEnum) => void
}

interface SentimentSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  emoji: string
  description: string
}

const SentimentSelectItem = React.forwardRef<HTMLDivElement, SentimentSelectItemProps>(
  ({ emoji, label, description, ...others }: SentimentSelectItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar>{emoji}</Avatar>

        <Box sx={{ flex: 1 }}>
          <Text size="sm">{label.replace(emoji, '')}</Text>
          <Text size="xs" opacity={0.65} lineClamp={4} sx={{ whiteSpace: 'normal' }}>
            {description}
          </Text>
        </Box>
      </Group>
    </div>
  )
)

SentimentSelectItem.displayName = 'SentimentSelectItem'

export const CharacterSentimentSelect: React.FC<Props> = ({
  sentiment,
  onSentimentChange,
  variant,
}) => (
  <Select
    value={sentiment}
    onChange={onSentimentChange}
    variant={variant}
    dropdownPosition="top"
    placeholder="Pick one"
    itemComponent={SentimentSelectItem}
    data={CHARACTER_SENTIMENTS}
    maxDropdownHeight={400}
  />
)
